import { jsx } from "react/jsx-runtime";
import { useState, useRef, useEffect } from "react";
import { Head } from "@tecnobit-srl/head-manager";
import md5 from "md5";
function Script({
  src,
  onload,
  async = true,
  id
}) {
  const [initialized, setInitialized] = useState(false);
  const script = useRef(null);
  useEffect(() => {
    if (!initialized) {
      const oldScript = script.current;
      if (oldScript) {
        document.head.removeChild(oldScript);
        script.current = null;
      }
      setInitialized(true);
      const scriptEl = document.createElement("script");
      scriptEl.src = src;
      if (async) {
        scriptEl.async = true;
      }
      if (onload) {
        scriptEl.onload = onload;
      }
      document.head.appendChild(scriptEl);
      script.current = scriptEl;
      return () => {
        document.head.removeChild(scriptEl);
        script.current = null;
      };
    }
    return () => {
    };
  }, [async, initialized, onload, src]);
  return /* @__PURE__ */ jsx(Head, { headKey: `script-${md5(src)}`, children: /* @__PURE__ */ jsx("script", { id, src, onLoad: onload, async }) });
}
export {
  Script
};
